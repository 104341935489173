@font-face {
  font-family: "title-en";
  src: url("../../fonts/LemonMilk.woff2") format("woff2"), url("../../fonts/LemonMilk.woff") format("woff"), url("../../fonts/LemonMilk.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "text-en";
  src: url("../../fonts/times-new-roman.woff2") format("woff2"), url("../../fonts/times-new-roman.woff") format("woff"), url("../../fonts/times-new-roman.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "title-kh";
  src: url("../../fonts/Hanuman.woff2") format("woff2"), url("../../fonts/Hanuman.woff") format("woff"), url("../../fonts/Hanuman.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "text-kh";
  src: url("../../fonts/Hanuman.woff2") format("woff2"), url("../../fonts/Hanuman.woff") format("woff"), url("../../fonts/Hanuman.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "text-en";
}

span.font,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "text-en";
  font-weight: 700;
}

span.font.title,
h1.title,
h2.title,
h3.title,
h4.title,
h5.title,
h6.title {
  text-transform: capitalize !important;
}

.page-area,
.navbar-are,
.footer-area {
  width: 100%;
  padding: 0;
}

.container {
  width: 90%;
  max-width: 1920px;
}

.msb-4 {
  margin-bottom: 4%;
}

.sy-4 {
  padding-top: 4%;
  padding-bottom: 4%;
}

.sy-6 {
  padding-top: 6%;
  padding-bottom: 6%;
}

.sy-5 {
  padding-top: 5%;
  padding-bottom: 5%;
}

.sb-4 {
  padding-bottom: 4%;
}

.text-body {
  padding-top: 15px;
}

.line-blue,
.line-blue-center,
.line-red-center,
.line-red {
  content: "";
  width: 60px;
  position: relative;
  height: 3px;
}

.bg-green {
  background-color: #51be95;
}

.one-line {
  -webkit-line-clamp: 1;
  /* number of lines to show */
}

.two-line {
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-height: 1.5;
  height: 45px;
}

.three-line {
  -webkit-line-clamp: 3;
}

.one-line,
.two-line,
.three-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.line-blue-center {
  background: #cf9455;
  margin: 10px auto;
}

.line-blue {
  background: #cf9455;
  margin-top: 10px;
}

.line-red {
  background: #153158;
}

.line-red-center {
  background: #153158;
  margin: 10px auto;
}

.b-radius {
  border-radius: 4px;
}

.b-shadow {
  box-shadow: #cccccc 0 0 16px;
}

.btn-box {
  display: inline-block;
  align-items: center;
  text-transform: uppercase;
  border-radius: 4px;
}

.btn-box a {
  padding: 10px 20px;
  display: inline-block;
  border: 2px solid #cf9455;
  color: #cf9455;
  transition-duration: 0.5s;
}

.btn-box a:hover {
  background-color: #cf9455;
  color: white;
}

.modal {
  top: 5% !important;
}

.modal .modal-dialog.display {
  width: 80%;
}

a {
  color: #153158;
}

a:hover {
  text-decoration: none;
}

.bg-img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
}

.head h2.title {
  font-size: 40px;
  color: #153158;
}

.center {
  display: flex;
}

.content-center {
  display: flex;
  align-items: center;
  vertical-align: middle;
  width: 100%;
}

.content-center.course-box {
  padding: 12% 0;
  justify-content: center;
  color: white;
  border-radius: 20px;
}

.content-center.course-box .icon-course {
  padding-bottom: 15px;
}

.content-box .content-text {
  padding: 15px 0;
}

.swiper-container {
  padding: 0;
}

.swiper-button-next {
  right: 0;
}

.swiper-button-prev {
  left: 0;
}

.swiper-button-next,
.swiper-button-prev {
  height: 40px;
  width: 40px;
  background-color: white;
  border: 2px solid #cf9455;
  display: inline-block;
  margin: 0 auto;
  z-index: 99 !important;
  transition-duration: 0.5s;
  top: 40%;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  height: 40px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #cf9455;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background-color: #cf9455;
}

.swiper-button-next:hover:after,
.swiper-button-prev:hover:after {
  color: white !important;
}

.modal {
  top: 15%;
  padding-right: 0 !important;
}

.modal-header {
  border-bottom: none;
}

.modal-header .close {
  position: absolute;
  right: 25px;
  top: 23px;
  height: 40px;
  width: 40px;
  opacity: 1;
  color: #fff;
  padding: 10px;
  background-color: #003664;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-dialog.display {
  width: 60%;
  max-width: 1920px;
}

.modal-dialog.display .modal-content {
  overflow-y: scroll;
}

.modal-dialog.display .modal-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

.modal-dialog.display .modal-content::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
}

.modal-dialog.display .modal-content::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.modal-body .image {
  justify-content: center;
}

.modal-body .image img {
  height: 150px;
}

.modal-body .content-text {
  height: 500px;
  overflow: auto;
}

.modal-body .content-text::-webkit-scrollbar {
  width: 2px;
  background-color: #fff;
}

.modal-body .content-text::-webkit-scrollbar-thumb {
  background-color: #000;
}

.modal-body .content-text p,
.modal-body .content-text ul {
  margin: 0;
}

.con-social ul.nav li a {
  font-size: 30px;
  padding: 0 10px 0 0;
}

.no-data {
  text-align: center;
  color: #153158;
  width: 200px;
  line-height: 16px;
  padding: 10px;
  margin: 0 auto;
  background-color: #e0e0e0b3;
}

.list-cus-say .row.swiper-wrapper {
  margin: 0;
}

.list-cus-say .row.swiper-wrapper .testimonial {
  padding: 20px;
}

.list-cus-say .row.swiper-wrapper .testimonial .image {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
}

.list-cus-say .row.swiper-wrapper .testimonial .pos {
  padding-bottom: 15px;
}

.list-cus-say .row.swiper-wrapper .testimonial .text-body {
  color: white !important;
}

.list-cus-say .swiper-button-next,
.list-cus-say .swiper-button-prev {
  top: 50%;
}

.zoom {
  transition: transform 0.5s, filter 1.5s ease-in-out;
}

.zoom:hover {
  transform: scale(1.1);
}

.head-text {
  width: 50%;
  font-size: 18px;
  margin: 0 auto;
}

.bg-img {
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.loading {
  display: none;
  background-color: #efefef;
  position: fixed;
  width: 100%;
  justify-content: center;
  height: 100%;
  align-items: center;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 99999999;
}

.loading .lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.loading .lds-ripple div {
  position: absolute;
  border: 4px solid #153158;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.loading .lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

html:lang(kh) body {
  font-family: "text-kh", sans-serif;
}

html:lang(kh) body p,
html:lang(kh) body span {
  font-size: 16px !important;
}

html:lang(kh) body span.font,
html:lang(kh) body h1,
html:lang(kh) body h2,
html:lang(kh) body h3,
html:lang(kh) body h4,
html:lang(kh) body h5,
html:lang(kh) body h6,
html:lang(kh) body #menu .col-9.center-menu ul.nav li a.nav-link {
  font-family: "title-kh", title-en, sans-serif !important;
}

html:lang(kh) body span.font.title,
html:lang(kh) body h1.title,
html:lang(kh) body h2.title,
html:lang(kh) body h3.title,
html:lang(kh) body h4.title,
html:lang(kh) body h5.title,
html:lang(kh) body h6.title,
html:lang(kh) body #menu .col-9.center-menu ul.nav li a.nav-link.title {
  text-transform: capitalize !important;
}

html:lang(kh) body #menu .col-9.center-menu ul.nav li a.nav-link {
  font-size: 16px;
}

.website {
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: fixed;
  top: 50%;
  z-index: 99999;
  border-right: 3px solid #cf9455;
  background-color: #0f2b4fd4;
  left: 0px;
  height: 50px;
  font-family: "Roboto", sans-serif;
}

.website .loader {
  display: flex;
}

.website .loader .text {
  font-size: 16px;
  padding-left: 14px;
  color: white;
}

.website .loader .dots {
  display: flex;
  position: relative;
  top: 20px;
  left: -10px;
  width: 100px;
  animation: dots 4s ease infinite 1s;
}

.website .loader .dots div {
  position: relative;
  width: 5px;
  height: 5px;
  margin-right: 10px;
  border-radius: 100%;
  background-color: white;
}

.website .loader .dots div:nth-child(1) {
  width: 0px;
  height: 0px;
  margin: 5px;
  margin-right: 15px;
  animation: show-dot 4s ease-out infinite 1s;
}

.website .loader .dots div:nth-child(4) {
  background-color: transparent;
  animation: dot-fall-left 4s linear infinite 1s;
}

.website .loader .dots div:nth-child(4):before {
  position: absolute;
  width: 5px;
  height: 5px;
  margin-right: 10px;
  border-radius: 100%;
  background-color: white;
  content: "";
  animation: dot-fall-top 4s cubic-bezier(0.46, 0.02, 0.94, 0.54) infinite 1s;
}

@keyframes dots {
  0% {
    left: -10px;
  }

  20%, 100% {
    left: 10px;
  }
}

@keyframes show-dot {
  0%, 20% {
    width: 0px;
    height: 0px;
    margin: 5px;
    margin-right: 15px;
  }

  30%, 100% {
    width: 5px;
    height: 5px;
    margin: 0px;
    margin-right: 10px;
  }
}

@keyframes dot-fall-left {
  0%, 5% {
    left: 0px;
  }

  100% {
    left: 30px;
  }
}

@keyframes dot-fall-top {
  0%, 5% {
    top: 0px;
  }

  30%, 100% {
    top: 10px;
  }
}

@media (max-width: 768px) {
  .head h2.title {
    font-size: 30px;
  }

  .btn-box a {
    padding: 5px 10px;
    font-size: 12px;
  }
}

@media (max-width: 568px) {
  .container {
    width: 95%;
    max-width: 1920px;
  }
}

@media (max-width: 480px) {
  .modal-body .content-text {
    height: 400px;
  }
}

